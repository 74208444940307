<template>
  <div class="navbar-expand-lg nav-light navbar__login-wrapper">
    <div class="container-fluid container-fluid-login">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <a class="navbar-brand" @click="handleBackUrl">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
                fill="#F2F2F2"
              />
            </svg>
          </a>
          <h6 class="title-navbar-login">
            Login
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="card card__form-login">
      <div class="card-body card__body-form">
        <h6 class="title-form">
          Masuk Ke Akun
        </h6>
        <form @submit.prevent="handleSubmitLogin" class="needs-validation" novalidate>
          <div class="input-group input__group mb-3">
            <span class="input-group-text input__text-login" id="basic-addon1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9599 13.7101C15.8896 13.3722 15.5587 13.1552 15.2206 13.2256C14.8827 13.2959 14.6658 13.6269 14.7362 13.9648C14.7767 14.1595 14.7284 14.359 14.6038 14.5123C14.5316 14.6011 14.3706 14.75 14.1038 14.75H1.89713C1.63032 14.75 1.46935 14.6011 1.39716 14.5123C1.27251 14.359 1.22426 14.1595 1.26479 13.9648C1.91341 10.8494 4.66138 8.57356 7.83479 8.49681C7.88976 8.49894 7.94498 8.5 8.00048 8.5C8.05616 8.5 8.1116 8.49891 8.16679 8.49678C10.4022 8.55041 12.4683 9.68262 13.7195 11.5487C13.9117 11.8354 14.2999 11.9119 14.5867 11.7197C14.8734 11.5275 14.95 11.1393 14.7577 10.8526C13.7322 9.32306 12.243 8.21088 10.5451 7.65203C11.5798 6.87613 12.2505 5.63991 12.2505 4.25C12.2505 1.90656 10.3439 0 8.00048 0C5.65701 0 3.75048 1.90656 3.75048 4.25C3.75048 5.64075 4.42198 6.87762 5.45776 7.65347C4.50876 7.96591 3.61829 8.45206 2.83891 9.09313C1.40979 10.2687 0.416133 11.9083 0.0410387 13.7101C-0.0765238 14.2747 0.06432 14.8545 0.427382 15.301C0.788664 15.7452 1.32438 16 1.89713 16H14.1038C14.6766 16 15.2123 15.7452 15.5735 15.301C15.9367 14.8545 16.0775 14.2747 15.9599 13.7101ZM5.00048 4.25C5.00048 2.59578 6.34626 1.25 8.00048 1.25C9.6547 1.25 11.0005 2.59578 11.0005 4.25C11.0005 5.85328 9.73626 7.16684 8.15232 7.24619C8.10179 7.24525 8.05116 7.24478 8.00048 7.24478C7.95004 7.24478 7.8996 7.24528 7.84923 7.24622C6.26501 7.16719 5.00048 5.8535 5.00048 4.25Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              type="email"
              class="form-control form__input"
              placeholder="Email/No. HP"
              v-model="email"
              @keyup="handleKeyUpEmail"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.email }}
            </div>
          </div>
          <div class="input-group input__group mb-3">
            <span class="input-group-text input__text-login" id="basic-addon1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8274 5.1861C15.4936 4.33629 14.8816 3.11014 13.8613 2.09465C12.8446 1.08265 11.6314 0.488153 10.7927 0.167592C9.87199 -0.184313 8.82572 0.0374989 8.12728 0.732652L5.67298 3.17546C4.94545 3.89955 4.73576 5.01373 5.15117 5.94794C5.27998 6.23769 5.42007 6.5211 5.56976 6.79491L0.183062 12.1816C0.0658435 12.2988 0 12.4578 0 12.6235V15.3735C0 15.7187 0.279811 15.9985 0.624998 15.9985H3.37499C3.72017 15.9985 3.99998 15.7187 3.99998 15.3735V14.2485L5.12498 14.2485C5.47017 14.2485 5.74998 13.9687 5.74998 13.6235V12.4985H6.87497C7.22016 12.4985 7.49997 12.2187 7.49997 11.8735C7.49997 11.5284 7.22016 11.2485 6.87497 11.2485H5.12498C4.77979 11.2485 4.49998 11.5284 4.49998 11.8735V12.9985L3.37499 12.9985C3.0298 12.9985 2.74999 13.2784 2.74999 13.6235V14.7485H1.25V12.8824L6.79232 7.34013C6.99594 7.1365 7.0341 6.82016 6.88475 6.57397C6.66816 6.21697 6.46919 5.83548 6.29341 5.44007C6.08719 4.97629 6.19226 4.42226 6.55485 4.06136L9.00912 1.61859C9.35953 1.26987 9.88446 1.15865 10.3466 1.33521C11.0722 1.61259 12.1181 2.12315 12.9795 2.98058C13.8463 3.8432 14.3739 4.90454 14.6639 5.64298C14.8449 6.10385 14.7349 6.62744 14.3838 6.97694L11.8998 9.44924C11.5381 9.80921 10.9982 9.91446 10.5243 9.71737C10.1335 9.55481 9.75496 9.36168 9.39934 9.1434C9.10518 8.96284 8.72031 9.05496 8.53975 9.34915C8.35919 9.64334 8.45128 10.0282 8.74547 10.2088C9.15646 10.461 9.59346 10.684 10.0443 10.8715C10.9862 11.2633 12.0607 11.0527 12.7816 10.3352L15.2655 7.86294C15.9701 7.16172 16.1906 6.11101 15.8274 5.1861Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              type="password"
              v-model="password"
              class="form-control form__input"
              placeholder="Password"
              @keyup="handleKeyUpPassword"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.password }}
            </div>
          </div>
          <div class="forgot__password-wrapper">
            <router-link to="/forgot-password" class="forgot__password-text">Lupa kata sandi?</router-link>
          </div>

          <div class="form-group signin-group">
            <button
              type="submit"
              class="btn btn-sm btn-warning login-button w-100"
              :disabled="isDisabledLogin || isLoading"
            >
              Masuk Ke Akun
            </button>
            <router-link to="/register" class="btn btn-sm btn-light register-button w-100"
              >Buat Akun Baru</router-link
            >
          </div>
        </form>
      </div>
    </div>

    <div class="form-group social-group">
      <h6 class="title__social">
        atau lebih cepat
      </h6>
      <button
        type="button"
        class="btn btn-sm btn-light btn-login-google w-100"
        @click="handleSignInGoogle"
      >
        <span class="iconify icon-google" data-icon="mdi:google" data-inline="false" />
        Masuk Dengan Google
      </button>
      <!-- <button
        type="button"
        class="btn btn-sm btn-light btn-login-facebook w-100"
        @click="handleSignInFacebook"
      >
        <span class="iconify icon-facebook" data-icon="mdi:facebook" data-inline="false" />
        Masuk Dengan Facebook
      </button> -->
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'

export default {
  data() {
    return {
      email: null,
      password: null,
      messageError: {
        email: null,
        password: null
      },
      disabled: true,
      isLoading: false
    }
  },
  computed: {
    isDisabledLogin() {
      if (
        this.email === '' ||
        this.email === null ||
        this.password === '' ||
        this.password === null ||
        this.messageError.email !== null ||
        this.messageError.password !== null
      ) {
        return true
      }

      return false
    }
  },
  methods: {
    handleBackUrl() {
      const queryParams = this.$route.query.redirectUrl
      if (queryParams) {
        this.$router.resolve({ redirect: (window.location.href = queryParams) })
      } else {
        this.$router.push({
          path: '/'
        })
      }
    },
    handleSubmitLogin() {
      this.isLoading = true
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$store
        .dispatch('account/login', {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.isLoading = false
          const queryParams = this.$route.query.redirectUrl
          if (queryParams) {
            this.$router.resolve({ redirect: (window.location.href = queryParams) }) 
          }else{
            this.$router
              .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
              .then(() => {
                // location.reload()
            })
          }
          
          swal.close()
          swal.hideLoading()
        })
        .catch(error => {
          this.isLoading = false
          this.$store.dispatch('notification/error', error)
          // if (error.response.status === 401) {
          //   swal.close()
          //   swal.hideLoading()
          //   if (error.response.data.hint === 'invalid email') {
          //     swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Periksa kembali email/no. hp dan password anda'
          //     })
          //   } else if (error.response.data.hint === 'invalid password') {
          //     swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Periksa kembali email/no. hp dan password anda'
          //     })
          //   } else if (error.response.data.hint === 'invalid user') {
          //     swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Periksa kembali email/no. hp dan password anda'
          //     })
          //   } else {
          //     swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Terjadi kesalahan'
          //     })
          //   }
          // }
        })
    },
    handleSignInGoogle() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      const GoogleAuth = window.gapi.auth2.getAuthInstance()
      if (GoogleAuth.isSignedIn.get()) {
        const profile = GoogleAuth.currentUser.get().getBasicProfile()
        const authResponse = GoogleAuth.currentUser.get().getAuthResponse()
        this.$store
          .dispatch('account/loginWithGoogle', {
            username: profile.getEmail(),
            // eslint-disable-next-line @typescript-eslint/camelcase
            provider_token: authResponse.access_token
          })
          .then(() => {
            // this.isLoading = false
            GoogleAuth.signOut()
            this.$router
              .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
              .then(() => {
                // location.reload()
              })
            swal.close()
            swal.hideLoading()
          })
          .catch(error => {
            this.isLoading = false
            if (error.response.status == 401) {
              if (error.response.data.hint == 'invalid user') {
               
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Periksa kembali email/no. hp dan password anda'
                })
              } else if (error.response.data.hint == 'invalid password') {
                swal.close()
                swal.hideLoading()
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Periksa kembali email/no. hp dan password anda'
                })
              } else if (error.response.data.hint == 'invalid email') {
                swal.close()
                swal.hideLoading()
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Periksa kembali email/no. hp dan password anda'
                })
              } else {
                swal.close()
                swal.hideLoading()
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Terjadi kesalahan'
                })
              }
            }
          })
      } else {
        GoogleAuth.signIn()
          .then(() => {
            const profile = GoogleAuth.currentUser.get().getBasicProfile()
            const authResponse = GoogleAuth.currentUser.get().getAuthResponse()
            this.$store
              .dispatch('account/loginWithGoogle', {
                username: profile.getEmail(),
                // eslint-disable-next-line @typescript-eslint/camelcase
                provider_token: authResponse.access_token
              })
              .then(() => {
                // this.isLoading = false
                this.$router
                  .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
                  .then(() => {
                    // location.reload()
                  })
                swal.close()
                swal.hideLoading()
              })
              .catch(error => {
                swal.close()
                swal.hideLoading()
                this.isLoading = false
                if (error.response.status == 401) {
                  if (error.response.data.hint == 'invalid user') {
                    swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Periksa kembali email/no. hp dan password anda'
                    })
                  } else if (error.response.data.hint == 'invalid password') {
                    swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Periksa kembali email/no. hp dan password anda'
                    })
                  } else if (error.response.data.hint == 'invalid email') {
                    swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Periksa kembali email/no. hp dan password anda'
                    })
                  } else {
                    swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Terjadi kesalahan'
                    })
                  }
                }

                if (error.response.status === 500) {
                  swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Terjadi kesalahan'
                  })
                }
              })
              .catch(() => {
                swal.close()
                swal.hideLoading()
              })
          })
          .catch(() => {
            swal.close()
            swal.hideLoading()
          })
      }
    },
    handleSignInFacebook() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })

      this.$store
        .dispatch('account/loginFacebook')
        .then(() => {
          this.$router
            .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
            .then(() => {
              // location.reload()
            })
          swal.close()
          swal.hideLoading()
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          this.isLoading = false
          if (error.response.status == 401) {
            if (error.response.data.hint == 'invalid user') {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Periksa kembali email/no. hp dan password anda'
              })
            } else if (error.response.data.hint == 'invalid password') {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Periksa kembali email/no. hp dan password anda'
              })
            } else if (error.response.data.hint == 'invalid email') {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Periksa kembali email/no. hp dan password anda'
              })
            } else {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Terjadi kesalahan'
              })
            }
          }
        })
    },
    handleKeyUpEmail($event) {
      const formatEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const form = document.querySelector('.needs-validation')

      if (this.email === '' || this.email === null) {
        this.messageError.email = 'Email/No. HP tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        if (this.email.includes("@") && !formatEmail.test($event.target.value)) {
          this.messageError.email = 'Format email tidak valid'
          $event.target.required = true
          form.classList.add('was-validated')
        } else {
          this.messageError.email = null
          form.classList.remove('was-validated')
          $event.target.required = false
        }
      }
    },
    handleKeyUpPassword($event) {
      const form = document.querySelector('.needs-validation')

      if ($event.target.value.length <= 7) {
        this.messageError.password = 'Password kurang dari 8 karakter'
        $event.target.required = true
        form.classList.add('was-validated')
      } else if (this.password === '' || this.password === null) {
        this.messageError.password = 'Password tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        this.messageError.password = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    }
  }
}
</script>
